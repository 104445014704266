<template>
    <div class="row">

        <div class="col-12 col-md-12">
            <q-table
                title="Elenco dei Simpli Validati"
                :data="righe"
                :columns="colonne"
                :filter="filter"
                row-key="prodotto_id"
                wrap-cells
            >
                    <template v-slot:top-right>
                        <div class="panel-cerca">
                            <q-input outlined dense debounce="300" v-model="filter" placeholder="Cerca" style="min-width: 350px;">
                                <template v-slot:append>
                                    <q-icon name="search" />
                                </template>
                            </q-input>
                        </div>
                    </template>

                    <template v-slot:header="props">
                        <q-tr :props="props">
                        <q-th auto-width />
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                        >
                            {{ col.label }}
                        </q-th>
                        <q-th></q-th>
                        </q-tr>
                    </template>

                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td auto-width>
                                <!-- <q-btn size="sm" color="accent" round dense @click="props.expand = !props.expand" :icon="props.expand ? 'remove' : 'add'" /> //-->
                            </q-td>

                            <q-td>{{ props.cols[0].value }}</q-td>
                            <q-td>{{ props.cols[1].value }}</q-td>
                            <q-td>{{ props.cols[2].value }}</q-td>
                            <q-td>{{ props.cols[3].value }}</q-td>
                            <q-td>{{ props.cols[4].value }}</q-td>
                            <q-td>{{ props.cols[5].value }}</q-td>
                            <q-td>{{ props.cols[6].value }}</q-td>
                        <!--    <q-td>{{ props.cols[7].value }}</q-td> //-->

                            <q-td>
                                <q-btn
                                    style="width: 200px;"
                                    color="deep-orange"
                                    glossy
                                    label="Visualizza"
                                    @click.native="onVisualizzaSimpli(props.row)"
                                />
                            </q-td>

                        </q-tr>

                    </template>

                </q-table>

            </div>

    </div>
</template>

<script>
    //import axios from 'axios';
    //import QQButton from "@/components/QQButton.vue";
    //import policy from "@/libs/policy";
    //import commonLib from "@/libs/commonLib";
    //import {mapFields} from "vuex-map-fields"
    import { mapActions } from "vuex";
    //import { exportFile } from 'quasar';

    export default {
        name: "SimpliDaCaricare",
        data() {
            return {
                filter: '',
                righe: [],
                colonne: []
            }
        },
        components: {
            //QQButton
        },
        computed: {

        },
        methods: {
            ...mapActions({
                fetchElencoSimpliValidati: "gestioneSimpli/fetchElencoSimpliValidati",
                setDatiPraticaPerSimplo: "gestioneSimpli/setDatiPraticaPerSimplo"
            }),
            onVisualizzaSimpli(record) {
                console.log("record:",record);
                this.setDatiPraticaPerSimplo({
                    guidPratica: record.numero,
                    idpratica: record.idpratica,
                    idcontratto: record.idcontratto,
                    idquietanza: record.idquietanza,
                    prodotto: record.prodotto,
                    compagnia: record.compagnia,
                    decorrenza: record.decorrenza,
                    scadenza: record.scadenza
                });

                this.$router.push({name : "Simpli.VisualizzaSimploValidato"});
            }
        },
        async mounted() {
            var dati     = await this.fetchElencoSimpliValidati();
            this.righe   = dati.rows;
            this.colonne = dati.columns;
        }
    }
</script>
